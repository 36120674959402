import { useState, useContext, useEffect } from 'react';
import {
  string, bool, func, oneOfType, object,
} from 'prop-types';
import {
  Modal, Button, Form, Input, Spin, Drawer, Checkbox,
} from 'antd';
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import crypto from 'crypto-js';

import FormInput from 'components/formInput';
import { useUser, userContext } from 'context/user';
import { useI18n } from 'context/i18n';
import { PowerSVG } from 'components/iconsSVG/power';
import { CloseMdSVG } from 'components/iconsSVG/close';
import ProtectionDataModal from 'components/protectionDataModal';
import { PrivacyPolicyText } from 'components/legalTexts';
import { recoverPassword } from 'actions/users';

import {
  handleStorage, getIsWideDesktop, getIsMobile,
} from 'utils/helpers';
import { dataLayerHandleEvent } from 'utils/dataLayers';
import { IS_CAMPER } from 'utils/constants/system';
import useDelayedState from 'hooks/useDelayedState';
import { useLocale, useValidate } from 'hooks';

import styles from './style.module.css';

const { SHA256 } = crypto;

export const ForgotPasswordForm = ({ onClickCancel }) => {
  const { t, locale } = useI18n();
  const validate = useValidate();
  const [ loading, setLoading ] = useState(false);
  const [ requestSend, setRequestSend ] = useState(false);
  const [ error, setError ] = useState(false);
  const [ forgotPasswordForm ] = Form.useForm();

  const handleRecover = async (data) => {
    setLoading(true);
    const result = await recoverPassword({ email: data.email, profile: locale });
    if (result.error) {
      setLoading(false);
      // sacar mensaje de error descriptivo?
      setError(t('mi.cuenta', 'usuario.incorrecto'));
    }
    setLoading(false);
    setRequestSend(true);
  };

  const onFinish = (values) => {
    handleRecover(values);
  };

  return (
    <>
      <p className={styles.myAccountTitle}>{t('mi.cuenta', 'recuperar.password.titulo', 'Retrieve your password')}</p>
      <p className={styles.accountDescription}>
        {t('mi.cuenta', 'recuperar.password.descripcion.1', 'Enter the email address you used to register on Camper.com')}
      </p>
      <p className={styles.accountDescription}>
        {t('mi.cuenta', 'recuperar.password.descripcion.2', 'We will send the link to access your account to the same email address.')}
      </p>
      <div className={styles.myAccountloginForm}>
        <Form
          form={forgotPasswordForm}
          layout="vertical"
          initialValues={{
            email: '',
          }}
          name="forgotPassword"
          onFinish={onFinish}
          requiredMark='optional'
          scrollToFirstError
          className="customForm"
        >
          <FormInput
            name="email"
            rules={validate('email')}
            floatLabel={t('mi.cuenta', 'mi.cuenta.email').replace(':', '')}
            required
          >
            <Input autoFocus={true} placeholder="" type="email" formNoValidate />
          </FormInput>

          {error &&
            <p className={styles.errorText}>
              {error}
            </p>
          }
          <Form.Item>
            <Button loading={loading} className={styles.createAccountBtn} type="primary" block htmlType="submit">{t('mi.cuenta', 'recuperar.password', 'Retrieve your password')}</Button>
          </Form.Item>
          {requestSend &&
            <p>{t('mi.cuenta', 'recuperar.password.peticion.enviada', 'The recovery email has been sent, please check your inbox.')}</p>
          }
          <p className={styles.cancelBtn} onClick={!loading ? onClickCancel : () => true}>{t('mi.cuenta', 'recuperar.password.volver', 'Volver')}</p>
        </Form>
      </div>
    </>
  );
};

ForgotPasswordForm.propTypes = {
  label: string,
  onClose: func,
  onClickCancel: func,
};

ForgotPasswordForm.defaultProps = {
  label: 'Identifícate',
  onClose: null,
  onClickCancel: () => true,
};

export const MyAccountLoginForm = ({ onClose, onClickForgot }) => {
  const { login } = useUser();
  const { t, locale } = useI18n();
  const validate = useValidate();
  const [ loading, setLoading ] = useState(false);
  const [ error, setError ] = useState(false);
  const [ loginForm ] = Form.useForm();

  const handleLogin = async (data) => {
    setLoading(true);
    const result = await login(data.email, data.password, locale);
    if (result.error) {
      setLoading(false);
      // sacar mensaje de error descriptivo?

      setError(t('mi.cuenta', 'usuario.incorrecto'));
    }

    if (result.info) {
      handleStorage('removeItem', 'shippingForm');
      handleStorage('setItem', 'shippingForm', JSON.stringify({
        ...result.info,
        name: result.info.username,
        telephone: result.info.telephone && result.info.telephone.length ? result.info.telephone.split(' ').splice(1).join('') : '',
      }));

      dataLayerHandleEvent({
        event: 'eventGA',
        eventCat: 'myAccount',
        eventAct: 'Login',
        eventLbl: window.location.href,
      });

      dataLayerHandleEvent({
        event: 'mailShadowed',
        mailSHA256: SHA256(data.email?.toLowerCase()).toString(),
      });

      dataLayerHandleEvent({
        event: 'login',
        method: 'email',
      });

      if (onClose) {
        onClose(true);
      }
    }
  };

  const onFinish = (values) => {
    handleLogin(values);
  };

  return (
    <>
      {IS_CAMPER ? <img src="/assets-new/my-account-hand.gif" alt="welcome login" width={90} height={90} /> : null}
      <p className={styles.myAccountTitle}>{t('mi.cuenta', 'login.titulo.bienvenido', 'Good to see you again')}</p>
      <p className={styles.accountDescription}>
      {t('mi.cuenta', 'identificate.acceder')}
      </p>
      <div className={styles.myAccountloginForm}>
        <Form
          form={loginForm}
          layout="vertical"
          initialValues={{
            email: '',
            password: '',
          }}
          name="loginAccount"
          onFinish={onFinish}
          requiredMark='optional'
          scrollToFirstError
          className="customForm"
        >
          <FormInput
            name="email"
            rules={validate('email')}
            floatLabel={t('mi.cuenta', 'mi.cuenta.email').replace(':', '')}
            required
          >
            <Input autoFocus={true} placeholder="" type="email" formNoValidate/>
          </FormInput>
          <FormInput
            name="password"
            rules={validate('password')}
            floatLabel={t('mi.cuenta', 'password')}
            required
          >
            <Input placeholder="" type="password" />
          </FormInput>
          {error &&
            <p className={styles.errorText}>
              {error}
            </p>
          }

          <p className={styles.forgotAccountP} onClick={onClickForgot}>{t('mi.cuenta', 'forgot.your.password', 'Forgot your password?')}</p>

          <Form.Item>
            <Button loading={loading} className={styles.createAccountBtn} type="primary" block htmlType="submit">{t('mi.cuenta', 'identificate')}</Button>
          </Form.Item>

        </Form>
      </div>
    </>
  );
};

MyAccountLoginForm.propTypes = {
  label: string,
  onClose: func,
  onClickForgot: func,
};

MyAccountLoginForm.defaultProps = {
  label: 'Identifícate',
  onClose: null,
  onClickForgot: () => true,
};

export const MyAccountRegisterForm = ({ onSuccess, initialValues }) => {
  const {
    createAccount,
  } = useUser();
  const { t, locale, profileData } = useI18n();
  const { country } = useLocale();
  const validate = useValidate();
  const [ errorMessage, setErrorMessage ] = useState(null);
  const [ isCreatingUser, setIsCreatingUser ] = useState(false);
  const [ privacyPolicyModalVisible, setPrivacyPolicyModalVisibile ] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const [ createAccountForm ] = Form.useForm();

  const handleCreateAccount = async (data) => {
    let errorInCreate = false;
    setIsCreatingUser(true);

    try {
      const recaptchaToken = await executeRecaptcha('create_account');
      const result = await createAccount(locale, {
        email: data.email, password: data.password, captchaToken: recaptchaToken, newsletter: data.newsletter === true,
      });
      if (result && result.error === false) {
        if (result.info) {
          handleStorage('removeItem', 'shippingForm');
          handleStorage('setItem', 'shippingForm', JSON.stringify({
            ...result.info,
            name: result.info.username,
            telephone: result.info.telephone && result.info.telephone.length ? result.info.telephone.split(' ').splice(1).join('') : '',
          }));
        }

        dataLayerHandleEvent({
          event: 'eventGA',
          eventCat: 'myAccount',
          eventAct: 'Register',
          eventLbl: window.location.href,
        });

        dataLayerHandleEvent({
          event: 'mailShadowed',
          mailSHA256: SHA256(data.email?.toLowerCase()).toString(),
        });

        dataLayerHandleEvent({
          event: 'sign_up',
          method: 'email',
        });

        onSuccess();
      } else {
        errorInCreate = result.errorMessage;
      }
    } catch (e) {
      errorInCreate = e;
    }

    if (errorInCreate !== false) {
      console.error(errorInCreate);
      setErrorMessage(typeof errorInCreate === 'string' ? errorInCreate : t('generico', 'error.500'));
    }

    setIsCreatingUser(false);
  };

  const onFinish = async (values) => {
    handleCreateAccount(values);
  };

  return (
    <>
      <h3 className={styles.myAccountTitle}>{t('mi.cuenta', IS_CAMPER ? 'mi.cuenta.camper' : 'mi.cuenta.nnormal')}</h3>
      <p className={styles.accountDescription}>
      {t('mi.cuenta', 'info.registro')}
      </p>
      <div className={styles.totalCostBreakdownWrapper}>
        <Form
          form={createAccountForm}
          layout="vertical"
          initialValues={initialValues}
          name="createAccount"
          onFinish={onFinish}
          // onFinishFailed={onFinishFailedShipmentDataForm}
          requiredMark='optional'
          scrollToFirstError
          className="customForm"
        >
          <FormInput
            name="email"
            rules={validate('email')}
            floatLabel={t('mi.cuenta', 'mi.cuenta.email', 'Email address').replace(':', '')}
            required
          >
            <Input placeholder="" type="email" formNoValidate/>
          </FormInput>
          <FormInput
            name="password"
            rules={validate('password')}
            floatLabel={t('mi.cuenta', 'input.contraseña', 'Create password')}
            required
          >
            <Input placeholder="" type="password" />
          </FormInput>
          <FormInput
            name="passwordRepeat"
            rules={validate('passwordRepeat')}
            dependencies={[ 'password' ]}
            floatLabel={t('mi.cuenta', 'repite.la.contrasena').replace(':', '')}
            required
          >
            <Input placeholder="" type="password" />
          </FormInput>
          <Form.Item>
            <div className={styles.privacyWrapper}>
              <p
                className="underline text-sm cursor-pointer"
                onClick={() => setPrivacyPolicyModalVisibile(true)}
              >
                {t('generico', IS_CAMPER ? 'camper.newsletter.proteccion.datos' : 'newsletter.proteccion.datos')}
              </p>
            </div>
          </Form.Item>
          <Form.Item name="privacy" valuePropName="checked" rules={validate('privacyPolicy')} className={styles.checkboxRow}>
            <Checkbox>
              {country === 'US' ? (
                <PrivacyPolicyText className={styles.checkboxLabel} />
              ) : (
              <span className={styles.checkboxLabel}>
                {t('compra.formulario', 'acepto.las')}&nbsp;
                <a className="font-bold" href={profileData.urlPrivacyPolicy} target="_blank" rel="noreferrer">{t('generico', 'privacy.conditions', 'privacy conditions')}</a>&nbsp;
                {t('compra.formulario', 'create.account')}
                {
                  country === 'TR' &&
                  <span>
                    <br/>
                    {t('compra.formulario', 'informacion.comercial.tr')}
                  </span>
                }
              </span>

              )}
            </Checkbox>
          </Form.Item>
          <ProtectionDataModal
            visible={privacyPolicyModalVisible}
            onCancel={() => setPrivacyPolicyModalVisibile(false)}
            origin="registro.myaccount"
            />
          <Form.Item valuePropName="checked" className={styles.checkboxRow} name="newsletter">
            <Checkbox>
              <span className={styles.checkboxLabel}>
                {t('compra.formulario', 'informacion.comercial')}
              </span>
            </Checkbox>
          </Form.Item>
          { errorMessage !== null && <p className="error">{errorMessage}</p>}
          <Spin spinning={isCreatingUser}>
            <Form.Item>
              <Button className={styles.createAccountBtn} type="primary" block htmlType="submit">{t('mi.cuenta', 'crear.cuenta')}</Button>
            </Form.Item>
          </Spin>
        </Form>
      </div>
    </>
  );
};

MyAccountRegisterForm.propTypes = {
  label: string,
  onSuccess: func,
  initialValues: object,
};

MyAccountRegisterForm.defaultProps = {
  label: 'Identifícate',
  initialValues: {
    email: '',
    password: '',
    passwordRepeat: '',
  },
};

function LoggedInLinks() {
  const { locale } = useLocale();
  const { userData, logout } = useUser();
  const { t } = useI18n();

  return (
    <div className={styles.menuDiv}>
      <p className={`${styles.menuP} ${styles.welcomeTitle}`}>
        { `${t('mi.cuenta', 'hola', 'Hello')}, `}
        <b>{userData.account?.username || userData.account?.email}</b>
      </p>
      <p className={styles.menuLink}><a href={`/${locale}/myaccount`}>{t('mi.cuenta', 'mi.cuenta', 'My Account')}</a></p>
      <p className={styles.menuLink}><a href={`/${locale}/myaccount/myorders`}>{t('mi.cuenta', 'mi.cuenta.pedidos.y.devoluciones', 'My Orders & Returns')}</a></p>
      {IS_CAMPER ? <p className={styles.menuLink}><a href={`/${locale}/myaccount/wishlist`}>{t('mi.cuenta', 'wishlist')}</a></p> : null}
      <p className={styles.menuLink}><a href={`/${locale}/myaccount/myprofile`}>{t('mi.cuenta', 'mi.perfil', 'My Profile')}</a></p>
      <p className={styles.logoutTrigger}>
        <span onClick={logout}>
          <PowerSVG />
        </span>
      </p>
    </div>
  );
}

export const MyAccountFormWrapper = ({ onClose, initialRetrievePassword }) => {
  const { userData } = useUser();
  const { t, locale } = useI18n();
  if (userData !== null) {
    return (
      <div className={styles.loggedInWrapper}>
        <LoggedInLinks />
      </div>
    );
  }
  const [ visibleLoginForm, setVisibleLoginForm ] = useState(true);
  const [ retrievePassword, setRetrievePassword ] = useState(initialRetrievePassword);

  const handleRegisterSuccess = () => {
    setVisibleLoginForm(true);
    onClose();
  };

  return (
    <div className={styles.myAccountFormWrapper}>
      {visibleLoginForm ?
        <>
          {!retrievePassword &&
            <>
              <MyAccountLoginForm onClose={onClose} onClickForgot={() => setRetrievePassword(true)} />
              <p
                className={styles.createAccountDetails}
                dangerouslySetInnerHTML={{ __html: t('mi.cuenta', 'crear.cuenta.detalles', 'Don’t have an account?<br/>Create an account to reap the benefits of a Camper account.') }}
              />
              <Button className={styles.loginBtn} block type="secondary" onClick={() => setVisibleLoginForm(!visibleLoginForm)}>
                {t('mi.cuenta', 'create.una.cuenta')}
              </Button>
            </>
          }
          {retrievePassword &&
            <>
              <ForgotPasswordForm onClose={onClose} onClickCancel={() => setRetrievePassword(false)} />
            </>
          }
        </> :
        <>
          <GoogleReCaptchaProvider
            reCaptchaKey={process.env.RECAPTCHA_KEY}
            language={locale}
            useRecaptchaNet
            scriptProps={{
              async: false,
              defer: false,
              appendTo: 'body',
              nonce: undefined,
            }}
          >
            <MyAccountRegisterForm onSuccess={handleRegisterSuccess}/>
          </GoogleReCaptchaProvider>
          <Button type='link' className={styles.backButton} onClick={() => setVisibleLoginForm(!visibleLoginForm)}>
            {t('mi.cuenta', 'volver.al.login').replace('&lt;', '<')}
          </Button>
        </>
      }
    </div>
  );
};

MyAccountFormWrapper.propTypes = {
  label: string,
  onClose: func,
  initialRetrievePassword: bool,
};
MyAccountFormWrapper.defaultProps = {
  label: 'Identifícate',
  initialRetrievePassword: false,
};

export const MyAccountButton = ({ handleOnClick }) => {
  const { t } = useI18n();
  return (
    <Button className={styles.loginBtn} type="secondary" onClick={handleOnClick}>
      {t('mi.cuenta', 'identificate')}
    </Button>
  );
};

MyAccountButton.propTypes = {
  handleOnClick: func,
};

export const MyAccountModal = ({
  visible, onClose, initialRetrievePassword, targetUrl,
}) => {
  const { locale } = useI18n();
  const isWideDesktop = getIsWideDesktop(); // 1440px
  useEffect(() => {
    if (isWideDesktop && visible) {
      const headerSelector = document.querySelector('.headroom');
      const hasHeader = headerSelector !== null && headerSelector !== undefined;
      const isScrolled = document.querySelector('.headroom') ? !document.querySelector('.headroom').className.includes('headroom--unfixed') : false;
      const ribbonDiv = document.querySelector('#header-ribbon');
      const ribbonHeight = ribbonDiv ? ribbonDiv.offsetHeight : 0;
      const top = IS_CAMPER ? isScrolled ? '43px' : `${(hasHeader ? 43 : 0) + ribbonHeight}px` : 0;
      document.querySelector('.ant-drawer').style.top = top;
    }
  }, [ visible, isWideDesktop ]);

  const handleClose = (result) => {
    if (targetUrl !== false) {
      const url = targetUrl || `${window.location.origin}/${locale}/myaccount`;
      window.location.assign(url);
    }

    onClose(result);
  };

  if (isWideDesktop) {
    return (
      <Drawer
        placement="right"
        onClose={onClose}
        visible={visible}
        destroyOnClose={true}
        width={360}
        closeIcon={<CloseMdSVG />}
      >
        <MyAccountFormWrapper
          onClose={handleClose}
          initialRetrievePassword={initialRetrievePassword}
        />
      </Drawer>
    );
  }
  return (
    <Modal
      title=""
      visible={visible}
      footer={null}
      onCancel={onClose}
      closeIcon={<CloseMdSVG />}
      wrapClassName={styles.modalWrapper}
    >
      <MyAccountFormWrapper onClose={handleClose} />
    </Modal>
  );
};

MyAccountModal.propTypes = {
  visible: bool,
  origin: string,
  onClose: func,
  initialRetrievePassword: bool,
  targetUrl: oneOfType([ string, bool ]),
};

MyAccountModal.defaultProps = {
  visible: false,
  onClose: () => true,
  initialRetrievePassword: false,
};

function MyAccountWidget({ onClickYourOrders, onClickSignIn }) {
  const {
    userData, loading,
  } = useContext(userContext);
  const { t } = useI18n();
  const { language, country } = useLocale();

  const [ visible, setVisible ] = useDelayedState(false);
  const isMobile = getIsMobile('767.5px');

  const onClickAccount = () => {
    if (isMobile) {
      onClickSignIn();
    }
  };

  return (
    <div
      className={styles.accountIcon}
      onMouseEnter={() => setVisible(true)}
      onMouseLeave={() => setVisible(false, 300)}
      onClick={onClickAccount}
    >
      <span className={styles.label}>
        {t('mi.cuenta', 'mi.cuenta', 'MY ACCOUNT')}
      </span>
      {visible &&
        (<div className={styles.accountMenu}>
          <Spin spinning={loading}>
            {userData !== null ?
              (<div key="myaccountlogedin" className={`${styles.menuOverlay} ${styles.menuOverlayLogedIn}`}>
                <LoggedInLinks />
              </div>) : (
                <div key="myaccount" className={styles.menuOverlay}>
                  <div className={styles.menuDiv}>
                    <p className={`${styles.menuP} ${styles.title}`}>
                      {t('mi.cuenta', IS_CAMPER ? 'mi.cuenta.camper' : 'mi.cuenta.nnormal')}
                    </p>
                    <p className={`${styles.menuP} ${styles.description}`}>{t('mi.cuenta', 'identificate.aprovecha.ventajas')}</p>
                    <MyAccountButton handleOnClick={onClickSignIn} />
                    <p className={`${styles.menuP} ${styles.title}`}>
                      {t('mi.cuenta', 'mis.pedidos', 'Orders')}
                    </p>
                    <p className={`${styles.anOrder} ${styles.description}`}>{t('mi.cuenta', country === 'JP' ? 'manage.an.order.no.exchange' : 'manage.an.order')}</p>
                    <Button className={styles.ordersBtn} data-lang={language} block onClick={onClickYourOrders}>
                      {t('menu.superior', 'mis.pedidos')}
                    </Button>
                  </div>
                </div>
              )}
          </Spin>
        </div>)
      }
    </div>
  );
}

MyAccountWidget.propTypes = {
  onClickSignIn: func,
  onClickYourOrders: func,
};

export default MyAccountWidget;
