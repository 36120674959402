import { get, post } from 'utils/apiWrapper';
import { prepareHeadersFromContext } from 'utils/helpers';

export const createContact = ({ lang, token, values }) => new Promise((resolve, reject) => {
  const path = '/contacts';

  const formdata = new FormData();
  formdata.append('profile', lang);
  formdata.append('token', token);
  const filesNodes = [ 'files', 'fichero' ];
  Object.entries(values).forEach((value) => {
    if (value[0] !== 'agreement') {
      if (filesNodes.includes(value[0])) {
        value[1].forEach((file) => formdata.append(value[0], file));
      } else {
        formdata.append(value[0], value[1]);
      }
    }
  });

  post({
    path,
    data: formdata,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
    .then(({ data }) => {
      const {
        status,
      } = data;
      if (status !== 'OK') {
        reject(status);
      } else {
        resolve({ status });
      }
    }).catch(reject);
});

export const getContactInfo = ({
  context, lang, email, s,
}) => new Promise((resolve, reject) => {
  const path = `/contacts?profile=${lang}&email=${email}&s=${s}`;
  const headers = prepareHeadersFromContext(context);

  get({ path, headers }).then(({ data }) => {
    if (data.status === 'OK') {
      resolve(data);
    } else {
      reject(data.errors);
    }
  });
});

export const getContactFormInfo = ({
  context, lang,
}) => new Promise((resolve, reject) => {
  const path = `/contacts/form?profile=${lang}`;
  const headers = prepareHeadersFromContext(context);

  get({ path, headers }).then(({ data }) => {
    if (data.status === 'OK') {
      delete data.audit;
      delete data.status;
      resolve(data);
    } else {
      reject(data.errors);
    }
  });
});
