import { useI18n } from 'context/i18n';
import { useLocale } from 'hooks';
import { useEffect, useRef } from 'react';
import { interpolate } from 'utils/StringUtils';

export function GiftCardTermsText({ className, onTermsClick }) {
  // Cuando se use solo este componente, mover modal de condiciones aqui dentro
  const { t, profileData } = useI18n();
  const { country } = useLocale();
  const giftCardTermsRef = useRef(null);

  // este check no sera necesario cuando se cambie a que todos los paises tengan politica de privacidad de esta forma. lo que si cambiara es el sufijo de la tag de traduccion
  if (country !== 'US') return null;

  useEffect(() => {
    if (giftCardTermsRef.current !== null) {
      // find first span in giftCardTermsRef
      const span = giftCardTermsRef.current.querySelector('span');
      // add click event to span
      if (span) span.addEventListener('click', onTermsClick);
    }
  }, []);

  return (
    <span ref={giftCardTermsRef} className={className ?? ''} dangerouslySetInnerHTML={{
      __html: interpolate(
        t('generico', 'gift.card.terms.us', 'I\'ve read and accepted the <span> Terms and conditions</span> and <a href="<TAG privacy_policy_url>" target="_blank" rel="noreferrer">Privacy policy</a>.'),
        {
          '<TAG privacy_policy_url>': profileData?.urlPrivacyPolicy,
        },
      ),
    }}/>
  );
}

export function PrivacyPolicyText({ className }) {
  const { t, profileData } = useI18n();
  const { country } = useLocale();
  // este check no sera necesario cuando se cambie a que todos los paises tengan politica de privacidad de esta forma. lo que si cambiara es el sufijo de la tag de traduccion
  if (country !== 'US') return null;

  return (
    <span className={className ?? ''} dangerouslySetInnerHTML={{
      __html: interpolate(
        t('generico', 'privacy.policy.us', 'I\'ve read and accepted the <a href="<TAG privacy_policy_url>"> privacy policy</a> to become a member of The Walking Society and to receive commercial communications from Camper, including Newsletters and/or SMS'),
        {
          '<TAG privacy_policy_url>': profileData?.urlPrivacyPolicy,
          '<TAG terms_url>': profileData?.urlTermsAndConditions,
        },
      ),
    }}/>
  );
}

export function TermsAndConditionsText({ className }) {
  const { t, profileData } = useI18n();
  const { country } = useLocale();
  // este check no sera necesario cuando se cambie a que todos los paises tengan politica de privacidad de esta forma. lo que si cambiara es el sufijo de la tag de traduccion
  if (country !== 'US') return null;

  return (
    <span className={className ?? ''} dangerouslySetInnerHTML={{
      __html: interpolate(
        t('generico', 'terms.and.conditions.us', 'I\'ve read and accepted the <a href="<TAG terms_url>"> terms and conditions</a> and <a href="<TAG privacy_policy_url>"> privacy policy</a>.'),
        {
          '<TAG terms_url>': profileData?.urlTermsAndConditions,
          '<TAG privacy_policy_url>': profileData?.urlPrivacyPolicy,
        },
      ),
    }}/>
  );
}
export function TakebackTermsAndConditionsText({ className }) {
  const { t, profileData } = useI18n();
  const { country, language } = useLocale();
  // este check no sera necesario cuando se cambie a que todos los paises tengan politica de privacidad de esta forma. lo que si cambiara es el sufijo de la tag de traduccion
  if (country !== 'US') return null;
  return (
    <span className={className ?? ''} dangerouslySetInnerHTML={{
      __html: interpolate(
        t('takeback', 'terms.and.conditions.us', 'I would like to become a member of the The Walking Society by agreeing to Camper’s <a href="<TAG privacy_policy_url>"> Privacy Policy</a> and <a href="<TAG takeback_url>"> Terms and Conditions of Take back</a>.'),
        {
          '<TAG takeback_url>': `/html/legal/takeback/TC_TB_${language.toUpperCase()}`,
          '<TAG privacy_policy_url>': profileData?.urlPrivacyPolicy,
        },
      ),
    }}/>
  );
}
