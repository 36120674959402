import { useI18n } from 'context/i18n';
import { useEffect, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { CopySVG } from './iconsSVG/copy';
import { CheckSVG } from './iconsSVG/check';

export function CopyToClipboard({ text, className }) {
  const { t } = useI18n();
  const [ state, setState ] = useState('idle'); // idle | copied
  const timer = useRef(null);

  // si desmontamos el componente, limpiamos el timer
  useEffect(() => () => {
    clearTimeout(timer.current);
  }, []);

  const onCopy = () => {
    navigator.clipboard.writeText(text);
    setState('copied');
    timer.current = setTimeout(() => {
      setState('idle');
    }, 2000);
  };

  return (
    <div className={twMerge('flex items-center justify-center', className)}>
      <div className="group flex border border-black h-10 items-center">
        <span className='flex flex-grow items-center justify-center px-4 m-0'>{text}</span>
        <button
          className="h-full w-10 text-white bg-black flex items-center justify-center text-xs text-dark-medium/80 transition-colors hover:text-dark"
          onClick={onCopy}>
          {state === 'idle' ? <CopySVG className="size-6" /> : <CheckSVG className="size-4" />}
        </button>
      </div>
    </div>
  );
}
