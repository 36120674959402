export const CheckSVG = (props) => (
  <svg height={10} viewBox="0 0 15 10" width={15} className="text-success" {...props}>
    <path
      d="m0 2.90584295 4.84704131 5.09415705 7.96129509-8"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      transform="translate(1 1)"
    />
  </svg>
);
