import {
  useState, useEffect, useContext, useRef,
} from 'react';
import { AutoComplete } from 'antd';

import { dataLayerHandleEvent } from 'utils/dataLayers';
import { KEYCODES } from 'utils/constants/ux';
import { updateQuerystringParam } from 'utils/helpers';

import { i18nContext } from 'context/i18n';
import { useIsMounted } from 'hooks';

import styles from './style.module.css';

const minSearchLength = 1;
let timeoutHandler = null;

function SearchTNM() {
  const {
    t, locale, setSearchQuery, searchQueryShadow, setSearchQueryShadow, profileData,
  } = useContext(i18nContext);
  const [ value, setValue ] = useState('');

  // FIXME: No usa el set, así que podemos cambiar la lógica que lo usa
  const [ lastTerm, setLastTerm ] = useState('');
  const [ expandedSearch, setExpandedSearch ] = useState(false);
  const isMounted = useIsMounted();
  const inputRef = useRef(null);

  const possibleEmptyValuesOfT = [
    '["",""]',
    '["buscador.unbxd.api.key","buscador.unbxd.site.key"]',
  ];

  const unbxdTranslations = [ t('buscador', 'unbxd.api.key'), t('buscador', 'unbxd.site.key') ];
  const areTranslationsEmpty = possibleEmptyValuesOfT.some((item) => JSON.stringify(unbxdTranslations) === item);

  const newSearch = profileData?.config?.unbxd === true && !areTranslationsEmpty;

  useEffect(() => {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    const searchValue = params.searchTerm;
    if (searchValue && searchValue !== '') {
      setValue(searchValue);
    }
    if (process.browser) {
      window.triggerSearch = (searchStr) => {
        if (!isMounted()) return;

        if (!window.location.pathname.includes('/search')) {
          window.location.assign(`${window.location.origin}/${locale}/search?target=A&searchTerm=${searchStr}`);
        } else {
          updateQuerystringParam(window.location.search, 'searchTerm', searchStr);
          updateQuerystringParam(window.location.search, 'target', 'A');
          updateQuerystringParam(window.location.search, 'serp', 'new');
          setSearchQuery(searchStr);
          setValue(searchStr);
          document.querySelector('#unbxd-search-box').value = searchStr;
        }
      };
    }
  }, []);

  const onSearch = () => {
  };

  const letsSearch = (searchStr) => {
    if (!searchStr || searchStr.trim().length < minSearchLength ||
        (lastTerm !== null && searchStr.trim().toLowerCase() === lastTerm.toLowerCase())) {
      return;
    }
    setSearchQueryShadow(searchStr);

    dataLayerHandleEvent({
      event: 'virtualPage',
      pageName: window.location.pathname + window.location.search,
    });
  };

  const handleKeyUp = async (event) => {
    const searchStr = event.target.value;

    if (event.code === KEYCODES.enter) {
      if (!isMounted()) return;

      event.target?.blur();
      if (!window.location.pathname.includes('/search')) {
        window.location.assign(`${window.location.origin}/${locale}/search?target=A&searchTerm=${searchStr}`);
      } else {
        updateQuerystringParam(window.location.search, 'searchTerm', searchStr);
        updateQuerystringParam(window.location.search, 'target', 'A');
        updateQuerystringParam(window.location.search, 'serp', 'new');
        setSearchQuery(searchStr);
      }
    } else {
      clearTimeout(timeoutHandler);
      timeoutHandler = setTimeout(() => {
        letsSearch(searchStr);
      }, 500);
    }
  };

  const onChange = (data) => {
    setValue(data);
  };

  const onSelect = (data) => {
    window.location.assign(`${window.location.origin}/${locale}/search?target=A&searchTerm=${data}`);
  };

  const onClickExpandIcon = () => {
    const tnmMobile = document.querySelector('.tnm--mobile');
    const filterNav = document.querySelector('#plp-filter-nav');
    const searchInput = document.querySelector('.tnm--mobile [class*="searchInput"]:not(.tnm__modal *)');
    const tnmModal = document.querySelector('.tnm__modal');
    let forcedCloseTnm = false;
    if (tnmModal && tnmModal.style.display !== 'none') {
      forcedCloseTnm = true;
      document.querySelector('.tnm__modal .tnm__close').click();
    }
    if (tnmMobile) {
      if (tnmMobile.style.height === '54px' || tnmMobile.style.height === '' || forcedCloseTnm) {
        tnmMobile.style.height = '100px';
        setExpandedSearch(true);
        if (inputRef?.current) {
          if (searchInput && searchInput.style) {
            searchInput.style.display = 'inline-block';
            if (forcedCloseTnm) {
              document.querySelector('.tnm--mobile [class*="searchInput"]:not(.tnm__modal *)').style.top = '0px';
              inputRef.current.focus();
            }
          }
          inputRef.current.focus();
        }
      } else {
        tnmMobile.style.height = '54px';
        setExpandedSearch(false);

        if (searchInput && searchInput.style) {
          searchInput.style.display = 'none';
        }

        if (filterNav?.style?.top === '100px') {
          filterNav.style.top = '50px';
        }
      }
    }
  };

  const onClickSearchIcon = () => {
    if (!window.location.pathname.includes('/search')) {
      window.location.assign(`${window.location.origin}/${locale}/search?target=A&searchTerm=${searchQueryShadow}`);
    } else {
      updateQuerystringParam(window.location.search, 'searchTerm', searchQueryShadow);
      updateQuerystringParam(window.location.search, 'target', 'A');
      setSearchQuery(searchQueryShadow);
    }
  };
  const onFocus = () => {
    const currentValue = document.querySelector('#unbxd-search-box').value;
    if (currentValue !== value) {
      setValue(currentValue);
    }
  };
  return (
    <div className={styles.wrapper}>
      {
      newSearch ?
        <div id="search-test-b" className={`${styles.container} ${expandedSearch ? styles.expanded : styles.hidden}`}>
          <img className={styles.searchIcon} src='/assets-new/search.svg' width={30} height={30} alt='Search' onClick={onClickExpandIcon} />
          <AutoComplete
            id='unbxd-search-box'
            ref={inputRef}
            value={value}
            onSearch={onSearch}
            onKeyUp={handleKeyUp}
            onChange={onChange}
            onSelect={onSelect}
            placeholder={t('generico', 'input.placeholder', 'Search')}
            className={styles.searchInput}
            virtual={false}
            dropdownClassName={styles.searchDropdown}
            allowClear={false}
            backfill
            notFoundContent={null}
            showArrow
            suffixIcon={<img className={styles.suffixIcon} src='/assets-new/search.svg' width={17} height={17} alt='Search' onClick={onClickSearchIcon}/>}
            onFocus={onFocus}
          >
          </AutoComplete>
        </div> :
        <div id="search-test-a" className={`${styles.searchV1}`}>
          <a href={`/${locale}/search`}>
            <input className={styles.inputV1} type="text" placeholder={t('generico', 'input.placeholder', 'Search')} />
            <img className={styles.searchIconV1} src='/assets-new/search.svg' width={17} height={17} alt='Search'/>
          </a>
        </div>
      }
    </div>

  );
}

SearchTNM.propTypes = {

};

SearchTNM.defaultProps = {

};

export default SearchTNM;
