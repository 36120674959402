import {
  Button, Form,
  Input,
  Modal,
  Spin,
} from 'antd';
import {
  array, bool, func, string,
} from 'prop-types';
import {
  useContext,
  useState,
} from 'react';

import { i18nContext, useI18n } from 'context/i18n';
import { CloseMdSVG } from 'components/iconsSVG/close';
import {
  COOKIES, eraseCookie, setCookie, getMarket,
} from 'utils/helpers';
import FormInput from 'components/formInput';
import { getOrderInfo } from 'actions/products';
import { checkProfile } from 'actions/general';
import { IS_CAMPER } from 'utils/constants/system';
import { dataLayerHandleEvent } from 'utils/dataLayers';
import { useValidate } from 'hooks';

import styles from './style.module.css';

export const GlobalEOrdersWidget = ({
  onOpen, onClose, visible, onlyModal,
}) => {
  const { t } = useContext(i18nContext);
  const { profileData } = useI18n();
  const trackLink = IS_CAMPER ? 'https://web.global-e.com/Order/Track/mZMg' : 'https://web.global-e.com/Order/Track/gsBBd';
  const returnLink = IS_CAMPER ? 'https://web.global-e.com/returns/portal/mZMg' : 'https://web.global-e.com/Returns/Portal/gsBBd';
  const handleOnClose = () => {
    onClose();
  };

  return (
    <>
    {!onlyModal &&
      <div
        className={styles.accountIcon}
        onClick={onOpen}
      >
        <span className={styles.label}>
          {t('menu.superior', 'mis.pedidos')}
        </span>
      </div>
    }

      <Modal
        title={<h1 className={styles.globaleOrdersTitle}>{t('menu.superior', 'mis.pedidos')}</h1>}
        visible={visible}
        footer={null}
        onCancel={handleOnClose}
        centered={true}
        wrapClassName={styles.globaleOrdersModal}
        closable={true}
      >
        <p>{t('menu.superior', 'a.traves.de', '***Entering the order number and the same e-mail you used to place the order, you will accessto My Orders section, where you will be able to:')}</p>

        <ul className={styles.ableToList}>
          <li>{t('menu.superior', 'seguimiento.de.tus.pedidos.online')}</li>
          <li>{t('menu.superior', 'consultar.el.tracking.de.tu.pedido')}</li>
          <li>{t('menu.superior', 'gestionar.la.devolucion.de.un.pedido')}</li>
        </ul>

        <hr />

        <div className={styles.buttonsWrapper}>
          <a href={trackLink}>{t('menu.superior', 'tracking')}</a>
          <a href={returnLink}>{t('menu.superior', 'return.portal')}</a>
        </div>
      </Modal>
    </>
  );
};

GlobalEOrdersWidget.defaultProps = {
  onlyModal: false,
};

GlobalEOrdersWidget.propTypes = {
  onOpen: func,
  onClose: func,
  visible: bool,
  onlyModal: bool,
};

export function MyOrderModal({
  locale, visible, onClose,
}) {
  const [ loading, setLoading ] = useState(false);
  const [ orderNotFound, setorderNotFound ] = useState(false);
  const { t } = useI18n();
  const validate = useValidate();
  const [ orderErrorMessage, setOrderErrorMessage ] = useState(false);

  const market = getMarket(locale);

  const expirationDays = 1;
  const onFinish = async (values) => {
    setLoading(true);
    dataLayerHandleEvent({
      event: 'orders',
      event_category: 'orders',
      event_detail_1: '',
      event_detail_2: '',
    });
    try {
      const orderInfoRs = await getOrderInfo({
        lang: locale,
        email: values.email.trim(),
        locator: values.locator.trim(),
      });

      const { sapActive, message } = orderInfoRs;

      if (sapActive === false) {
        setLoading(false);
        setOrderErrorMessage(message);
        return false;
      }

      const order = { email: values.email.trim(), locator: values.locator.trim() };
      eraseCookie(COOKIES.ORDER_DETAILS);
      setCookie(COOKIES.ORDER_DETAILS, encodeURI(JSON.stringify(order)), expirationDays);
      setorderNotFound(false);
      let localeOrder = locale;
      if (orderInfoRs.countryId && orderInfoRs.languageId) {
        localeOrder = `${orderInfoRs.languageId}_${orderInfoRs.countryId}`;
      } else {
        const auxProfile = await checkProfile(localeOrder);
        localeOrder = auxProfile.defaultProfile;
      }
      window.location.assign(`${window.location.origin}/${localeOrder}/myaccount/myorders/orderdetails`);
      return true;
    } catch (error) {
      console.error(error);
      setorderNotFound(true);
      setLoading(false);
    }
    return false;
  };

  const handleStartOrdersEvent = (origin) => {
    dataLayerHandleEvent({
      event: 'start_orders',
      event_category: 'orders',
      event_detail_1: origin,
      event_detail_2: '',
    });
  };

  const handleTryOrdersEvent = () => {
    dataLayerHandleEvent({
      event: 'try_orders',
      event_category: 'orders',
      event_detail_1: '',
      event_detail_2: '',
    });
  };

  const handleErrorEvent = (data) => {
    const { errorFields } = data;

    if (errorFields) {
      errorFields.forEach((item) => {
        const { name, errors } = item;
        errors.forEach((error) => {
          dataLayerHandleEvent({
            event: 'error_orders',
            event_category: 'orders',
            event_detail_1: name.join(),
            event_detail_2: error,
          });
        });
      });
    }
  };

  return (
    <Modal
      title=""
      visible={visible}
      footer={null}
      onCancel={onClose}
      centered={true}
      wrapClassName={styles.modalWrapper}
      closable={false}
    >
      <Spin spinning={loading}>
        <span onClick={onClose} className={styles.closeSvg}>
          <CloseMdSVG />
        </span>
          <div className={styles.modalContent}>
            <p className={styles.title}>{t('menu.superior', 'mis.pedidos')}</p>
            <div className={styles.content}>
              <div className={styles.info}>
                <p>{t('menu.superior', 'a.traves.de')}</p>
                  <ul>
                    {market !== 'CN' &&
                      <li>{t('menu.superior', 'realizar.cambios.de.talla.o.de.modelo')}</li>
                    }
                    <li>{t('menu.superior', 'consultar.el.tracking.de.tu.pedido')}</li>
                    <li>{t('menu.superior', `gestionar.la.devolucion.de.un.pedido${market === 'MX' ? '.mx' : ''}`)}</li>
                  </ul>
              </div>
              <div className={styles.form}>
                <p>{t('menu.superior', 'mis.pedidos.info')}</p>
                <Form
                  layout="vertical"
                  name="orderForm"
                  onFinish={onFinish}
                  onFinishFailed={handleErrorEvent}
                  requiredMark='optional'
                  scrollToFirstError
                  className={`${styles.emailForm} customForm`}
                >
                  <FormInput
                    name="email"
                    rules={validate('email')}
                    floatLabel={t('menu.superior', 'email', 'Email')}
                    required
                  >
                    <Input formNoValidate type="email" onClick={() => handleStartOrdersEvent('email')} tabIndex="1" placeholder="" />
                  </FormInput>
                  <FormInput
                    name="locator"
                    rules={validate('orderId')}
                    floatLabel={t('menu.superior', 'n.de.pedido', 'Order Number')}
                    required
                  >
                    <Input onClick={() => handleStartOrdersEvent('code')} tabIndex="2" placeholder="" />
                  </FormInput>
                  <Form.Item>
                    <Button onClick={handleTryOrdersEvent} className={styles.submitBtn} tabIndex="28" type="primary" htmlType="submit" loading={loading}>{t('menu.superior', 'entrar', 'Enter')}</Button>
                  </Form.Item>
                  {orderNotFound &&
                    <p className={styles.formError}>{t('mis.pedidos', 'pedidos.no.encontrado')}</p>
                  }
                  {orderErrorMessage &&
                    <p className={styles.formError}>{orderErrorMessage}</p>
                  }
                </Form>
              </div>
              <div className={styles.footerPhoto}></div>
            </div>
          </div>
      </Spin>
    </Modal>
  );
}

MyOrderModal.defaultProps = {
  visible: false,
  onClose: () => false,
  countries: [],
};

MyOrderModal.propTypes = {
  visible: bool,
  onClose: func,
  locale: string,
  countries: array,
};

export default MyOrderModal;
