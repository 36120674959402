import { useState } from 'react';
import { string, bool, any } from 'prop-types';
import { Form, Checkbox } from 'antd';

import { useI18n } from 'context/i18n';
import { useLocale, useValidate } from 'hooks';

import ProtectionDataModal from 'components/protectionDataModal';
import { PrivacyPolicyText } from 'components/legalTexts';

import { IS_CAMPER } from 'utils/constants/system';

import styles from './style.module.css';

const PrivacyPolicy = ({
  origin, wrapperClass, checkBoxClass, hideConditions, overrideLabel,
}) => {
  const { t, profileData } = useI18n();
  const { country } = useLocale();
  const validate = useValidate();
  const [ dataModalVisible, setDataModalVisible ] = useState(false);

  const classForCheckbox = checkBoxClass === undefined ? styles.privacyCheckbox : `${styles.privacyCheckbox} ${checkBoxClass}`;

  const becomeMemberPart = overrideLabel || <>
  {t('compra.formulario', 'become.member')}
  {
    country === 'TR' &&
    <span>
      <br/>
      {t('compra.formulario', 'informacion.comercial.tr')}
    </span>
  }
  </>;

  return (
    <>
      <Form.Item className={wrapperClass}>
        <div className={styles.privacyWrapper}>
          <p
            className={styles.infoLink}
            onClick={() => setDataModalVisible(true)}
          >
            {t('generico', IS_CAMPER ? 'camper.newsletter.proteccion.datos' : 'newsletter.proteccion.datos', 'Data protection information')}
          </p>
        </div>
      </Form.Item>
      <Form.Item name="privacy" valuePropName="checked" rules={validate('privacyPolicy')}>
        <Checkbox
          tabIndex="27"
          className={country !== 'TR' ? classForCheckbox : `${classForCheckbox} ${styles.withMargin}`}
        >
          {country === 'US' ? (
            <PrivacyPolicyText className={styles.privacyAgreement} />
          ) : (
          <span className={styles.privacyAgreement}>
            {t('compra.formulario', 'acepto.las')}&nbsp;
            {
              !hideConditions && <>
                <a href={profileData.urlTermsAndConditions} target="_blank" rel="noreferrer">{t('compra.formulario', 'condiciones.generales')}</a>&nbsp;
                {t('compra.formulario', 'y.la')}&nbsp;
              </>
            }
            <a href={profileData.urlPrivacyPolicy} target="_blank" rel="noreferrer">{t('generico', 'privacy.conditions', 'privacy conditions')}</a>&nbsp;
            {becomeMemberPart}
          </span>

          )}
        </Checkbox>
      </Form.Item>
      <ProtectionDataModal
        visible={dataModalVisible}
        onCancel={() => setDataModalVisible(false)}
        origin={origin}
        />
    </>
  );
};

PrivacyPolicy.propTypes = {
  origin: string,
  wrapperClass: string,
  checkBoxClass: string,
  hideConditions: bool,
  overrideLabel: any,
};

PrivacyPolicy.defaultProps = {
  origin: 'newsletter',
  wrapperClass: '',
  hideConditions: false,
  overrideLabel: false,
};

export default PrivacyPolicy;
